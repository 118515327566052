<template>
  <Page>
    <template v-slot:content>
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar
              flat
            >
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Aujourd'hui
              </v-btn>
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
              >
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
              >
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer />
              <v-menu
                bottom
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Jour</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Semaine</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Mois</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="700">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
            />
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="true"
              :activator="selectedElement"
              min-width="550px"
              offset-x
            >
              <v-card
                color="grey lighten-4"
                flat
              >
                <v-toolbar
                  :color="selectedEvent.color"
                  dark
                >
                  <v-toolbar-title>
                    {{ selectedEvent.details?.title }}
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <div>Date avant première: {{ selectedEvent.details?.preLaunchAt|date }}</div>
                  <div>Date de début: {{ selectedEvent.details?.launchAt|date }}</div>
                  <div>Date de fin: {{ selectedEvent.details?.endAt|date }}</div>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </template>
  </Page>
</template>

<script>
import { fetchAllHydra } from '@/helpers/rest'
import Page from '@/components/core/Page.vue'
import Vue from 'vue'

export default {
  name: 'DropsCalendar',
  components: { Page },
  data() {
    return {
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        'blue', 'indigo', 'red', 'pink', 'purple', 'light-blue', 'teal', 'light-green', 'lime', 'amber', 'deep-orange',
        'deep-purple', 'cyan', 'green', 'orange',
      ].map(c => [c, c + ' darken-2', c + ' darken-4']).flat(1),
    }
  },
  async mounted () {
    const drops = await fetchAllHydra(
      Vue.axios,
      'catalog/drops?itemsPerPage=200&published=1&public=1&order[launch_at]=asc'
    )

    this.events = drops.map(drop => {
      const hash = drop.id.split('-').map(v => v.charCodeAt(0)).join('')

      const event = {
        name: drop.name,
        ripple: true,
        color: this.colors[parseInt(hash) % this.colors.length],
        timed: true,
        details: {
          title: drop.name + ' (' + drop.slug + ')',
          preLaunchAt: drop.pre_launch_at,
          launchAt: drop.launch_at,
          endAt: drop.end_at,
        },
      }

      const events = [
        {
          ...event,
          ...{
            name: `▶ Début ${event.name}`,
            start:  new Date(drop.launch_at),
          },
        },
      ]

      if (drop.end_at) {
        events.push({
          ...event,
          ...{
            name: `◀ Fin ${event.name}`,
            start:  new Date(drop.end_at),
          },
        })
      }

      return events
    }).flat()

    this.$refs.calendar.checkChange()
  },
  methods: {
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
  },
}
</script>
